<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="5" sm="8">
        <!-- 뒤로 -->
        <v-btn
          color="primary"
          icon
          style="margin-left: -10px"
          class="mt-8"
          @click="moveToBack()"
        >
          <v-icon color="priamry">mdi-chevron-left</v-icon>
        </v-btn>

        <!-- 페이지 제목 -->
        <h4 class="primary--text mb-4">LEGO</h4>

        <!-- 로딩 -->
        <div class="tester on ma-0 mb-4">
          <!-- isLoading -->
          <p>
            <b>isLoading:</b>
            <span class="red" :class="{ mint: $store.state.layout.isLoading }">
              {{ $store.state.layout.isLoading }}
            </span>
          </p>

          <!-- hr -->
          <v-divider class="my-4" />

          <v-btn color="mint" dark block @click="clickLoading()">LOADING</v-btn>
        </div>

        <!-- 알럿 -->
        <div class="tester on ma-0 mb-4">
          <!-- isAlert -->
          <p>
            <b>isAlert:</b>
            <code>{{ isAlert }}</code>
          </p>

          <!-- alertType -->
          <p>
            <b>alertType:</b>
            <code>{{ alertType }}</code>
          </p>

          <!-- alertTitle -->
          <p>
            <b>alertTitle:</b>
            <code>{{ alertTitle }}</code>
          </p>

          <!-- alertContent -->
          <p>
            <b>alertContent:</b>
            <code>{{ alertContent }}</code>
          </p>

          <!-- cancelText -->
          <p>
            <b>cancelText:</b>
            <code>{{ cancelText }}</code>
          </p>

          <!-- confirmText -->
          <p>
            <b>confirmText:</b>
            <code>{{ confirmText }}</code>
          </p>

          <!-- hr -->
          <v-divider class="my-4" />

          <v-btn color="mint" dark block @click="clickAlert()">ALERT</v-btn>
        </div>

        <!-- NOT FOUND -->
        <v-btn color="mint" outlined block @click="moveTo('NOT_FOUND')">
          NOT FOUND
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      isAlert: state => state.alert.isAlert,
      alertType: state => state.alert.alertType,
      alertTitle: state => state.alert.alertTitle,
      alertContent: state => state.alert.alertContent,
      cancelText: state => state.alert.cancelText,
      confirmText: state => state.alert.confirmText
    })
  },
  methods: {
    moveToBack() {
      this.$router.go(-1)
    },
    clickLoading() {
      this.$store.commit('toggleLoading', true)

      setTimeout(() => {
        this.$store.commit('toggleLoading', false)
      }, 2000)
    },
    moveTo(val) {
      let to = ''

      if (val === 'HOME') to = '/'
      if (val === 'NOT_FOUND') to = 'aaaa'

      this.$router.push(to).catch(() => {})
    },
    clickAlert() {
      this.showAlert({
        alertType: 'WITH_CONFIRM',
        // alertTitle: 'TITLE',
        alertContent: 'CONTENT CONTENT CONTENT<br/>CONTENT CONTENT CONTENT',
        cancelText: '',
        confirmText: '',
        confirmFunc: () => {
          console.log('**** CONFIRM FUNC')
        }
      })
    }
  }
}
</script>
